<template>
  <template>
    <v-snackbar v-model="snackbar" :color="snackbarColor" timeout="3000">
      {{ snackbarText }}
    </v-snackbar>
  </template>

  <v-app>
    <v-main>
      <v-container fluid>
        <v-row>
          <!-- Panell esquerre -->
          <v-col cols="3">
            <v-card class="mb-4">
              <v-card-title class="text-h4 bg-primary text-white">
                Demo API de Transcripció
              </v-card-title>

              <!-- Secció d'Inici de Sessió -->
              <v-card-text v-if="!isLoggedIn">
                <h2 class="text-h5 mb-4">Iniciar Sessió</h2>
                <v-form @submit.prevent="login">
                  <v-text-field v-model="username" label="Nom d'usuari" required></v-text-field>

                  <v-text-field v-model="password" label="Contrasenya" type="password" required></v-text-field>

                  <v-btn type="submit" color="primary" block>
                    Iniciar Sessió
                  </v-btn>
                </v-form>
                <v-alert v-if="loginStatus" :type="loginStatus.includes('error') ? 'error' : 'success'" class="mt-4">
                  {{ loginStatus }}
                </v-alert>
              </v-card-text>

              <!-- Secció de Pujada d'Arxiu -->
              <v-card-text v-if="isLoggedIn">
                <h2 class="text-h5 mb-4">Pujar i Transcriure</h2>
                <v-form @submit.prevent="uploadFile">
                  <v-file-input @change="handleFileUpload" label="Selecciona un arxiu d'àudio o vídeo"
                    prepend-icon="mdi-file-upload" required></v-file-input>

                  <v-select v-model="selectedModelId" :items="models" item-title="name" item-value="id" label="Model"
                    required></v-select>

                  <v-btn type="submit" color="primary" block>
                    Pujar i Transcriure
                  </v-btn>
                </v-form>

                <v-alert v-if="transcriptionStatus" type="info" class="mt-4">
                  {{ transcriptionStatus }}
                </v-alert>

                <h2 class="text-h5 mt-6 mb-4">Transcripcions Disponibles</h2>
                <div style="max-height: 400px; overflow-y: auto;">
                  <v-list>
                    <v-list-item v-for="transcription in transcriptions" :key="transcription.id">
                      <v-list-item-content style="padding-left: 8px;">
                        <v-list-item-title>
                          Arxiu: {{ transcription.original_filename }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          ID: {{ transcription.id }} - Estat: {{ transcription.status }} - Model: {{ transcription.model }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <template v-slot:prepend>
                        <div class="d-flex align-center">
                          <v-btn class="mr-4" @click="viewTranscription(transcription.id)" color="primary" size="small">
                            Veure
                          </v-btn>
                        </div>
                      </template>
                    </v-list-item>
                  </v-list>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Panell dret -->
          <v-col cols="9">
            <v-card v-if="isLoggedIn">
              <v-card-title class="text-h5">
                Visor de transcripció
              </v-card-title>
              <v-textarea v-if="selectedTranscription" v-model="selectedTranscription" rows="20" outlined></v-textarea>
              <v-alert v-else type="info">
                Selecciona una transcripció per carregar-la o be activa el micròfon per a transcripció en temps real.
              </v-alert>
            </v-card>
            <v-col cols="3" v-if="isLoggedIn">
              <v-btn ref="TalkButton" @click="toggleTalk()" :color="isRunning ? 'success' : 'primary'" block>
                {{ isTalking ? 'Desactivar micròfon' : 'Activar micròfon' }}
              </v-btn>
              <v-radio-group v-model="transcriptionModel" row>
                <v-radio label="Whisper" value="whisper"></v-radio>
                <v-radio label="Aina" value="aina"></v-radio>
              </v-radio-group>
            </v-col>

          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// import { ref } from 'vue'
import axios from 'axios'
import { config } from './config'

export default {
  data() {
    return {
      username: "",
      password: "",
      token: "",
      isLoggedIn: false,
      loginStatus: "",
      file: null,
      model: "",
      transcriptionStatus: "",
      transcriptions: [],
      selectedTranscription: null,
      models: [],
      selectedModelId: 'whisper',
      isTalking: false,
      // Create an AudioContext object
      audioContext: null,
      fromSampleRate: null,
      toSampleRate: 16000,
      processor: null,
      socket: null,
      isWebSocketConnected: false,
      isRunning: false,
      transcriptionModel: 'whisper'
    };
  },
  created() {
    console.log("API_BASE_URL=", config.API_BASE_URL);
    setInterval(() => {
      if (this.isLoggedIn) {
        this.fetchTranscriptions();
      }
    }, 1000);
    // this.audioContext = new AudioContext();
    // this.fromSampleRate = this.audioContext.sampleRate;
    // this.toSampleRate = 16000;
    // // Create a ScriptProcessorNode object with a buffer size of 4096 and one input and output channel
    // this.processor = this.audioContext.createScriptProcessor(4096, 1, 1);
    // // Define a function that will be called when the processor has audio data available
    // this.processor.onaudioprocess = (event) => {
    //   var input = event.inputBuffer.getChannelData(0);
    //   if (this.socket && this.socket.readyState === WebSocket.OPEN) {
    //     this.socket.send(this.downsample(input, this.fromSampleRate, this.toSampleRate));
    //   }
    // };
  },
  methods: {
    setupAxiosInterceptors() {
      // Interceptor para manejar errores de autenticación
      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response && error.response.status === 401) {
            // Token expirado o inválido
            this.handleLogout();
            this.showNotification('La sessió ha caducat. Si us plau, torna a iniciar sessió.', 'error');
          }
          return Promise.reject(error);
        }
      );
    },    

    async login() {
      const formData = new URLSearchParams();
      formData.append("username", this.username);
      formData.append("password", this.password);

      try {
        console.log("API_BASE_URL=", config.API_BASE_URL);
        const response = await axios.post(`${config.API_BASE_URL}/api_1_1/token`, formData);
        console.log("response: ", response);
        this.token = response.data.access_token;
        this.isLoggedIn = true;
        this.loginStatus = "Sessió iniciada correctament!";
        // Configurar interceptors
        this.setupAxiosInterceptors();
        this.fetchTranscriptions();
        this.fetchModels();
      } catch (error) {
        console.error("Error:", error);
        this.loginStatus = "Error d'inici de sessió. Si us plau, torna-ho a provar.";
      }
    },

    handleLogout() {
      // Limpiar el estado de la aplicación
      this.token = "";
      this.isLoggedIn = false;
      this.selectedTranscription = null;
      this.transcriptions = [];
      this.models = [];
      this.loginStatus = "";
      
      // Eliminar el token del localStorage
      // localStorage.removeItem('userToken');
      
      // Detener la transcripción en tiempo real si está activa
      if (this.isTalking) {
        this.stopTalking();
      }
      
      // Limpiar las credenciales
      this.username = "";
      this.password = "";
    },

    async fetchModels() {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/api_1_1/models`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        this.models = [...response.data.objectlist] || [];
        // this.models = response.data.objectlist.map(model => ({
        //   name: model.name,  // Cambia "name" si es necesario
        //   id: model.id      // Cambia "id" si es necesario
        // }));
        console.log("Models: ", this.models);
        console.log("Models[0]: ", this.models[0].name);
      } catch (error) {
        console.error('Error en obtenir els models:', error);
      }
    },

    handleFileUpload(event) {
      // Asegurarse de obtener el archivo del evento
      const files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;

      this.file = files[0]; // Guardar el archivo real, no el evento
    },

    async uploadFile() {
      if (!this.file) {
        this.showNotification('Si us plau, selecciona un arxiu.', 'error');
        return;
      }

      const formData = new FormData();
      formData.append("file", this.file);

      try {
        // if (this.selectedModelId === '') this.selectedModelId = 'whisper';
        const response = await axios.post(
          `${config.API_BASE_URL}/api_1_1/transcribe_batch?model=${this.selectedModelId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this.transcriptionStatus = `Transcripció iniciada. ID: ${response.data.id}, Estat: ${response.data.status}`;
        this.showNotification('Arxiu pujat correctament', 'success');
        this.fetchTranscriptions();
      } catch (error) {
        console.error("Error:", error);
        this.showNotification('Error en pujar l\'arxiu. ', 'error');
      }
    },

    async fetchTranscriptions() {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/api_1_1/transcriptions`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        this.transcriptions = response.data.transcriptions || [];
      } catch (error) {
        console.error("Error en obtenir les transcripcions:", error);
      }
    },

    showNotification(text, color) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    },

    async viewTranscription(id) {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/api_1_1/transcribe_batch/${id}/txt`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        this.selectedTranscription = response.data.transcription;
      } catch (error) {
        console.error("Error en obtenir la transcripció:", error);
      }
    },

    // TRANSCRIPCIÓ EN TEMPS REAL
    downsample(buffer, fromSampleRate, toSampleRate) {
      // buffer is a Float32Array
      var sampleRateRatio = Math.round(fromSampleRate / toSampleRate);
      var newLength = Math.round(buffer.length / sampleRateRatio);
      console.log("New length: ", newLength);
      var result = new Float32Array(newLength);
      var offsetResult = 0;
      var offsetBuffer = 0;
      while (offsetResult < result.length) {
        var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
        var accum = 0, count = 0;
        for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
          accum += buffer[i];
          count++;
        }
        result[offsetResult] = accum / count;
        offsetResult++;
        offsetBuffer = nextOffsetBuffer;
      }
      return result;
    },

    // Define a function that will request the access to the microphone and start the audio capture
    startTalking() {
      // Primero, cerramos cualquier conexión WebSocket existente
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.close();
      }

      // Detenemos cualquier contexto de audio existente
      if (this.audioContext) {
        this.audioContext.close();
      }
      console.log(`URL de WS: ${config.WS_BASE_URL}`);
      // Ahora ya lanzamos todo el proceso de escucha
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          // this.socket = new WebSocket("ws://192.168.1.38:6543/ws/");
          this.socket = new WebSocket(`${config.WS_BASE_URL}`);

          this.socket.onopen = () => {
            console.log("WebSocket connection opened.");
            this.isWebSocketConnected = true;
            this.isTalking = true;
            this.selectedTranscription = null; //reiniciem el texte de transcripcio
            this.socket.send(`<${this.transcriptionModel}>`) //Indico al server que modelo quiero
          };

          this.socket.onmessage = (event) => {
            // Recibir la transcripción y mostrarla
            switch (event.data) {
              case "<RUNNING>":
                //Senyal per activar el semàfor verd..
                this.isRunning = true;
                break;
              case "<CLOSING>":
                this.isRunning = false;
                break;
              default:
                // Si no es una instrucció, lo que arriba es texte transcrit
                if (this.selectedTranscription === null) { this.selectedTranscription = "" }
                this.selectedTranscription += event.data;
                break;
            }
          };

          this.socket.onerror = (error) => {
            console.error("WebSocket error: ", error);
            this.isWebSocketConnected = false;
            this.isTalking = false;
            this.isRunning = false;
          };

          this.socket.onclose = () => {
            console.log("WebSocket connection closed.");
            this.isWebSocketConnected = false;
            this.isTalking = false;
            this.isRunning = false;
          };

          // Crear y configurar AudioContext
          this.audioContext = new AudioContext();
          var source = this.audioContext.createMediaStreamSource(stream);
          this.processor = this.audioContext.createScriptProcessor(4096, 1, 1);
          this.fromSampleRate = this.audioContext.sampleRate;

          this.processor.onaudioprocess = (event) => {
            if (this.isWebSocketConnected) {
              console.log("onprocess audio: ", event);
              console.debug()
              var input = event.inputBuffer.getChannelData(0);
              try {
                const downsampled = this.downsample(input, this.fromSampleRate, this.toSampleRate);
                this.socket.send(downsampled);
              } catch (error) {
                console.error("Error al procesar o enviar audio: ", error);
              }
            } else {
              console.warn("WebSocket no está conectado. No se puede enviar audio.");
            }
          };

          source.connect(this.processor);
          this.processor.connect(this.audioContext.destination);
          console.log("Capture started")
        })
        .catch(error => {
          console.log(error.message);
        });
    },

    // Define a function that will stop the audio capture
    stopTalking() {
      // Disconnect the processor from the source and the destination
      this.processor.disconnect();
      // Close websocket
      if (this.socket) this.socket.close();
    },
    toggleTalk() {
      if (this.isTalking) {
        this.stopTalking();
      } else {
        this.startTalking();
      }
      this.isTalking = !this.isTalking;
    },

  },
};
</script>